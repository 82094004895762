import 'babel-polyfill'
import 'bootstrap'
// import {Swiper} from "swiper"
// Styling
import '../scss/style.scss'

// Fontawesome
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faFacebookF, faTwitter, faInstagram, faLinkedinIn, faPhoneAlt)
dom.watch()
var Sticky = require('sticky-js');

jQuery(document).ready(function ($) {
    if ($('.login.wp-core-ui').length) {
        $('#user_login').attr('placeholder', 'Gebruikersnaam of e-mailadres');
        $('#user_pass').attr('placeholder', 'Wachtwoord')
    }

    fadeIn();
    $(document).scroll(fadeIn);

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        let $target = $($.attr(this, 'href'));
        let top = $target.offset().top;
        let height = $target.outerHeight();
        let windowHeight = $(window).height();

        if (height > windowHeight) {
            $('html, body').scrollTop(top);
        } else {
            // Scroll to center
            $('html, body').scrollTop(top - (windowHeight / 2) + (height / 2));
        }
    });
    let swiperHomeText, swiperHomeImg;
    document.querySelectorAll(".swiper").forEach(x => {
        let extraConfig = x.matches(".homePageImgs,.homePageTexts") ? { slidesPerView: 1 } : {}
        if (x.matches(".homePageTexts")) {
            extraConfig.allowTouchMove = false
            extraConfig.autoHeight = true;
            extraConfig.pagination = {};
            extraConfig.navigation = {};
        } else if (x.matches(".homePageImgs")) {
            extraConfig.autoHeight = true;
        }
        let swiper = new Swiper(x, Object.assign({
            pagination: {
                el: '.swiper-pagination',
            },

            navigation: {
                nextEl: '.swiper-next, .swiper-round-next',
                prevEl: '.swiper-prev, .swiper-round-prev'
            }

        }, extraConfig));

        if (x.matches(".homePageImgs")) {
            swiperHomeImg = swiper
        } else if (x.matches(".homePageTexts")) {
            swiperHomeText = swiper
        }
    })
    if (swiperHomeText) {
        swiperHomeText.controller.control = swiperHomeImg;
        swiperHomeImg.controller.control = swiperHomeText;
    }

    $('.cases__filter-button').click(function (e) {
        if (e.target.matches(".cases__filter-button-content , .cases__filter-button-content * ")) {
            return;
        }
        $(this).find('.cases__filter-button-content').toggleClass('d-none');
    });
    if (document.querySelector(".typewrite")) {
        var TxtType = function (el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            el.innerHTML = "";
            let longestIndex = 0;
            this.toRotate.forEach((x) => {
                let tempText = x.split(" ").join("</span><span class='word'>")
                this.el.insertAdjacentHTML("beforeend", "<div class='line' style='opacity:0;'><span class='word'>" + tempText + " </span></div>")
                this.el
            })
            this.el.querySelectorAll(".line").forEach(x => {
                if (x.clientHeight > longestIndex) {
                    longestIndex = x.clientHeight;
                }
            })
            // this.el.children[longestIndex].style.postion="relative";
            el.style.minHeight = longestIndex + "px";
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 2000;
            this.txt = '';
            this.tick();
            this.isDeleting = false;
            window.wordIndex = Number(0);
            // INJECT CSS
            var css = document.createElement("style");
            css.type = "text/css";
            css.innerHTML = ".typewrite .word{opacity:0; transition:all .25s ease;display:inline-block;margin-right:10px;}" +
                ".typewrite .word.show{opacity:1; }" +
                ".typewrite .line{height:0}";
            document.body.appendChild(css);
        };

        TxtType.prototype.tick = function () {
            var i = this.loopNum % this.toRotate.length;
            if (this.loopNum > this.toRotate.length) {
                this.loopNum = i;
            }
            let currentLine = this.el.children[i];
            currentLine.style.opacity = "1";
            if (this.isDeleting) {
                window.wordIndex=0;
                
                Array.from(currentLine.children).forEach(x=>{
                    x.classList.remove("show")
                })
                currentLine.children[window.wordIndex ?? 0].classList.remove("show")
            } else {
                currentLine.children[window.wordIndex ?? 0].classList.add("show")
                window.wordIndex++;
            }
            var that = this;
            var delta = 100;

            if (this.isDeleting) { delta /= 2; }

            if (!this.isDeleting && window.wordIndex === currentLine.childElementCount) {
                delta = this.period;
                this.isDeleting = true;
            } else if (this.isDeleting && window.wordIndex === 0) {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }

            setTimeout(function () {
                that.tick();
            }, delta);
        };

        window.onload = function () {
            var elements = document.getElementsByClassName('typewrite');
            for (var i = 0; i < elements.length; i++) {
                var toRotate = elements[i].getAttribute('data-type');
                var period = elements[i].getAttribute('data-period');
                if (toRotate) {
                    new TxtType(elements[i], JSON.parse(toRotate), period);
                }
            }

        };
    }

    let lastCollapsible;
    if ($('.collapsibles__item-header').length) {
        function toggleSlide(container) {
            let content = container.querySelector(".collapsibles__item-content");
            if (!container.classList.contains('active')) {
                container.classList.add('active');

                content.style.height = 'auto';

                var height = content.clientHeight + 'px';

                content.style.height = '0px';

                setTimeout(function () {
                    content.style.height = height;
                }, 0);
            } else {
                content.style.height = '0px';
                container.classList.remove('active');
                content.addEventListener('transitionend', function () {
                    fadeIn()
                }, {
                    once: true
                });
            }

        };
        $('.collapsibles__item-header').click(function () {
            // $('.collapsibles__item').removeClass('active');
            if (typeof lastCollapsible !== typeof undefined && !lastCollapsible.isEqualNode(this)) {
                let container = lastCollapsible.parentElement;
                let content = container.querySelector(".collapsibles__item-content");
                content.style.height = '0px';

                // content.addEventListener('transitionend', function () {
                container.classList.remove('active');
            }
            // $(this).parent().toggleClass('active');
            toggleSlide(this.parentElement)

            lastCollapsible = this;

        });
      
        window.addEventListener("resize", e => {
            document.querySelectorAll(".collapsibles__item.active").forEach(container => {
                let content = container.querySelector(".collapsibles__item-content");
                content.style.height="auto";
                var height = content.clientHeight + 'px';
                content.style.height = height;
            })
        })
        if(window.innerWidth<992){
            // console.log(lastCollapsible);
            lastCollapsible.click();
        }

    }
    let uspSlide = document.querySelector(".usps-slider");
    if (uspSlide) {
        let swiper = new Swiper(uspSlide, {
            slidesPerView: 1.5,
            breakpoints: {
                700: {
                    slidesPerView: 2
                }
                , 800: {
                    slidesPerView: 2.5
                }
            },
            centeredSlides: true,
            autoplay: {
                delay: 3000
            },
            allowTouchMove: false,
            loop: true,
        });
        swiper
    }
    document.querySelectorAll(".blocks .expand").forEach(x => {
        x.addEventListener("click", e => {
            x.classList.toggle("active");
            let list = x.parentElement.querySelector(".content")
            if (x.classList.contains("active")) {
                list.style.maxHeight = "calc( " + list.childElementCount + " * 2rem)";
            } else {
                list.style.maxHeight = "calc( 3 * 2rem)";
            }

        })
    })
    document.querySelectorAll(".sticky").forEach(x=>{
        var sticky = new Sticky(".sticky");
    })

});

function fadeIn() {
    let $ = jQuery;
    $('.fade-in:not(.faded-in)').each(function () {
        let top = $(this).offset().top;
        let bottom = $(this).offset().top + $(this).outerHeight();
        let windowTop = $(window).scrollTop();
        let windowBottom = $(window).scrollTop() + $(window).innerHeight();

        if ((windowBottom - 100 > top) && (windowTop + 100 < bottom)) {
            $(this).addClass('faded-in');
            setTimeout(fadeIn, 100);
            return false;
        }
    })
}